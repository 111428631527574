<template>
  <v-app-bar bottom class="shadow-top" color="white"  height="95vh" fixed>
    <div class="navBottom tw-justify-end">
      <div>
        <base-button class="mr-5" button-text="Back" outlined height="3rem" width="6.938rem" @click="handleRouteBack">
          <template #left-icon>
            <arrow-left-icon  style="color: #008EEF" />
          </template>
        </base-button>
      </div>
      <div>
        <base-button button-text="Post Job" @click="openModal"></base-button>
      </div>
    </div>
    <SplashConfirmModal
        v-if="!editor"
        :dialog="dialog"
        :title="title"
        :loading="loading"
        @close="closeModal"
        :subTitle="subTitle"
        :noButtonText="cancel"
        :yesButtonText="post"
        @function="handlePublishJob"
    >
    </SplashConfirmModal>
    <PostJobModal @close="closeShare" :job-info="jobDetails" :dialog="shareModal"/>
  </v-app-bar>
</template>

<script>
import BaseButton from "../../UIComponents/BaseButton";
import {ArrowLeftIcon } from 'vue-feather-icons'
import SplashConfirmModal from "@/components/UIComponents/SplashConfirmModal";
import {postJob} from "@/services/api/APIService";
import PostJobModal from "@/components/UIComponents/PostJobModal";
import {mapGetters} from "vuex";

export default {
  name: "JobSummaryFooter",
  components: {PostJobModal, SplashConfirmModal, BaseButton,ArrowLeftIcon},
  data() {
    return {
      dialog: false,
      loading: false,
      title: 'Post this job',
      cancel: 'Cancel',
      shareModal:false,
      jobDetails:{},
      post: 'Post Job',
      subTitle: 'Posting this job makes it live, you will not be able to make any changes to the job opening once you proceed.'
    }
  },
  props:{
    routeBack : [String],
    editor :[String],
    logo:[String]
  },
  computed: {
    ...mapGetters('jobPosting',["jobInfo"]),
    sessionStorageId() {
      let job = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"));
      return job.id;
    },
  },

  methods:{
    openModal() {
      this.dialog = true
    },
    closeShare(){
      this.shareModal = false
      // this.$router.push({path: '/dashboard/jobs'})
      sessionStorage.removeItem("returnedSelectedDetails")
    },
    closeModal() {
      this.dialog = false
    },
    handleRouteBack(){
      this.$router.push({
        name: "Shortlisting",
        params: {
          jobId: this.sessionStorageId
        }
      })
    },
    handlePublishJob() {
      this.loading = true
      postJob(this.sessionStorageId).then( res=> {
        console.log(res)
        // if(res.statusText === 'OK') {
          const resMessage = 'Job published successfully'
          this.jobDetails = {...this.logo,...this.jobInfo}
          // sessionStorage.removeItem("returnedSelectedDetails")
          this.loading = false
          this.$displaySnackbar({message:resMessage, success:true})
          this.shareModal = true
          // this.$router.push({name: "JobSummaryOverview"})
          this.closeModal()
          // sessionStorage.removeItem('returnedSelectedDetails')
        // }
      }).catch(err =>{
        this.shareModal = true

        this.loading = false
        this.$displaySnackbar({message:err.response.data.detail, success:false})

      })
    },

  }
}
</script>

<style scoped lang="scss">
.custom-class{
  color: white;
}

.shadow-top{
  box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 0px 0px;
  border: 1px solid rgba(0, 0, 0, 0.04) !important;

}

</style>