  <template>
  <div class=" scroll">
    <Loader v-if="pageLoading" :loading="pageLoading" />
    <job-posting-layout v-else>
      <template #topContent>
        <div class="stepContent">
          <div>
            <p class="pre mb-10 tw-mt-5 lg:tw-mt-0">PREVIEW</p>
          </div>
          <div class="tw-flex">
            <p class="company-name tw-text-l md:tw-text-3xl text-capitalize">{{company.company_name}}</p>
          </div>
          <div class="tw-flex tw-justify-between">
            <p class="job-title tw-text-2xl md:tw-text-5xl">{{ jobInfo.title }}</p>
            <div class="lg:tw-flex md:tw-flex tw-justify-center tw-items-center">
              <base-button button-text="Post Job" @click="openModal" class="ml-3 tw-hidden lg:tw-block md:tw-block"></base-button>
              <SplashConfirmModal
                  :title="title"
                  :dialog="dialog"
                  :subTitle="subTitle"
                  @close="closeModal"
                  :noButtonText="cancel"
                  :yesButtonText="post"
                  :loading="loading"
                  @function="handlePublishJob"
              ></SplashConfirmModal>

            </div>
          </div>
          <div class="tw-flex tw-justify-items-start mb-10">
            <div class="tw-mt-5">
              <v-chip style="border: 1px solid #0C8B99 !important; padding: 10px" class="chip" :color="$vuetify.theme.themes.light.enumCrispgreen.enumCrispgreen">
                <BriefcaseIcon style="color: #475661" class="mr-1" size="1.5x"/>
                <span class="loc">{{jobInfo.type}}</span>
              </v-chip>
              <v-chip style="border: 1px solid #FFDA5E !important;" class="ml-3 chip" :color="$vuetify.theme.themes.light.enumYellow5.enumYellow5">
                <MapPinIcon style="color: #475661" class="mr-1" size="1.5x"/>
                <span class="loc">{{ jobInfo.is_remote === false ? 'On-Site' : 'Remote' }}</span>
              </v-chip>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-w-full lg:tw-flex-row lg:tw-justify-between">
            <div class="tw-flex tw-flex-col custom-width">
              <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4">
                <label class="profile">Location</label>
                <div class="tw-flex tw-items-center tw-cursor-pointer" @click="$router.push({name:'Job-stepFive',query:{'edit':'true'}})" >
                  <vue-feather type="edit-2" style="width: 13.22px; height: 13.22px" />
                  <label class="edit-text tw-ml-3">Edit</label>
                </div>
              </div>
              <v-divider/>
              <p class="details tw-text-sm md:tw-text-lg tw-pt-4">{{jobInfo.state + ', ' + jobInfo.country || 'N/A'}}</p>
            </div>
            <div class="tw-flex  tw-flex-col custom-width">
              <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4">
                <label class="profile">Salary Range</label>
                <div class="tw-flex tw-items-center tw-cursor-pointer" @click="$router.push({name:'Job-stepSeven',query:{'edit':'true'}})">
                  <vue-feather type="edit-2" style="width: 13.22px; height: 13.22px" />
                  <label class="edit-text tw-ml-3">Edit</label>
                </div>
              </div>
              <v-divider/>
              <div class="details tw-text-sm md:tw-text-lg tw-pt-4">
                <h6 v-if="getCurrency(jobInfo.currency)">
                  {{getCurrency(jobInfo.currency) + convertNumber(jobInfo.minimum_salary) + ' - ' + getCurrency(jobInfo.currency)+convertNumber(jobInfo.maximum_salary) || 'N/A'}}
                </h6>
                <h6 v-else>N/A</h6>
                </div>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-w-full lg:tw-mt-10">
            <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4">
              <label class="profile">Application Deadline</label>
              <div class="tw-flex tw-items-center tw-cursor-pointer" @click="$router.push({name:'Job-stepEight',query:{'edit':'true'}})">
                <vue-feather type="edit-2" style="width: 13.22px; height: 13.22px" />
                <label class="edit-text tw-ml-3">Edit</label>
              </div>
            </div>
            <v-divider/>
            <p class="details tw-text-sm md:tw-text-lg tw-pt-4">Closes in {{processedDate(jobInfo.application_deadline) || '0 day'}}</p>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col lg:tw-mt-20">
            <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4">
              <label class="profile">Job Description</label>
              <div class="tw-flex tw-items-center tw-cursor-pointer" @click="$router.push({name:'Job-stepNine',query:{'edit':'true'}})">
                <vue-feather type="edit-2" style="width: 13.22px; height: 13.22px" />
                <label class="edit-text tw-ml-3">Edit</label>
              </div>
            </div>
            <v-divider/>
          <div class="md:tw-w-8/12">
              <div class="tw-flex tw-mt-5">
                <span v-if="jobInfo.description" class="details tw-text-sm md:tw-text-lg" v-html="jobInfo.description"></span>
                <span class="details tw-text-sm md:tw-text-lg" v-else>N/A</span>
              </div>
          </div>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col lg:tw-mt-20">
            <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4">
              <label class="profile">Responsibilities</label>
              <div class="tw-flex tw-items-center tw-cursor-pointer" @click="$router.push({name:'Job-stepNine',query:{'edit':'true'}})">
                <vue-feather type="edit-2" style="width: 13.22px; height: 13.22px" />
                <label class="edit-text tw-ml-3">Edit</label>
              </div>
            </div>
            <v-divider/>
            <div class="md:tw-w-8/12">
              <div class="tw-flex tw-mt-5">
                <span v-if="jobInfo.responsibilities" class="details tw-text-sm md:tw-text-lg" v-html="jobInfo.responsibilities"></span>
                <span class="details tw-text-sm md:tw-text-lg" v-else>N/A</span>
              </div>
            </div>
          </div>

          <div class="tw-flex tw-w-full tw-flex-col lg:tw-mt-20">
            <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4">
              <label class="profile">Additional Information</label>
              <div class="tw-flex tw-items-center tw-cursor-pointer" @click="$router.push({name:'Job-stepSeven',query:{'edit':'true'}})">
                <vue-feather type="edit-2" style="width: 13.22px; height: 13.22px" />
                <label class="edit-text tw-ml-3">Edit</label>
              </div>
            </div>
            <v-divider/>
            <div class="md:tw-w-8/12">
              <div class="tw-flex tw-mt-5">
                <span v-if="jobInfo.additional_info" class="details tw-text-sm md:tw-text-lg" v-html="jobInfo.additional_info"></span>
                <span class="details tw-text-sm md:tw-text-lg" v-else>N/A</span>
              </div>
            </div>
          </div>

          <div class="tw-flex tw-w-full tw-flex-col lg:tw-mt-20">
            <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4">
              <label class="profile">Skills and Qualifications</label>
              <div class="tw-flex tw-items-center tw-cursor-pointer" @click="$router.push({name:'Job-stepFour',query:{'edit':'true'}})">
                <vue-feather type="edit-2" style="width: 13.22px; height: 13.22px" />
                <label class="edit-text tw-ml-3">Edit</label>
              </div>
            </div>
            <v-divider/>
            <div class="md:tw-w-8/12">
              <div class="tw-flex tw-mt-5">
                <span class="details tw-text-sm md:tw-text-lg">
                  <ul>
                    <li type="disc" v-if="jobInfo.qualifications.length" class="tw-py-5">
                    {{jobInfo.qualifications[0]}} (Minimum qualification)
                  </li>
                    <div v-for="(skill,index) in jobInfo.required_skills" :key="index">
                    <li type="disc" class="tw-py-5">Must be Proficient in {{skill}}</li>
                  </div>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col lg:tw-mt-20">
            <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4">
              <label class="profile">Shortlisting Criteria</label>
              <div class="tw-flex tw-items-center tw-cursor-pointer"
                   @click="$router.push({name:'Shortlisting',params:{jobId:sessionStorageId},query:{'edit':'true'}})" >
                <vue-feather type="edit-2" style="width: 13.22px; height: 13.22px" />
                <label class="edit-text tw-ml-3">Edit</label>
              </div>
            </div>
            <v-divider/>
          </div>
          <div class="md:tw-w-8/12">
            <div class="tw-flex tw-mt-5">
              <span class="details tw-text-sm md:tw-text-lg">
                <ul>
                  <li type="disc" class="tw-py-5" v-if="jobCriteria.use_age" >Must be {{jobCriteria.age_lower_limit + ' - '+ jobCriteria.age_upper_limit}}  Years of Age</li>
                  <li type="disc" class="tw-py-5" v-if="jobCriteria.use_years_of_experience">{{$route.params.experience}} Year(s) of Experience</li>
                  <li type="disc" class="tw-py-5" v-if="jobCriteria.use_salary">Expected Salary must be within
                    {{getCurrency(jobCriteria.currency) + convertNumber(jobCriteria.salary_lower_limit) + ' - ' + getCurrency(jobCriteria.currency)+convertNumber(jobCriteria.salary_upper_limit) || 'N/A'}}
                    </li>
                  <li type="disc" v-if="jobCriteria.use_highest_level_of_education" class="tw-py-5">
                    Must have no less than {{jobCriteria.highest_level_of_education}} or it’s equivalent
                  </li>
                  <div v-if="jobCriteria.use_certification"
                       class="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-py-5">
                    <li type="disc"></li> Must have
                    <label class="tw-px-1" v-for="cert in jobCriteria.certification" :key="cert">{{cert}}</label>
                  </div>
                  <div v-if="jobCriteria.use_location" class="tw-flex tw-flex-row tw-items-center tw-py-5">
                    <li type="disc"></li>
                    <label v-if="jobCriteria.is_remote">Must be ready to work Remote</label>
                    <label v-else> Must be in {{jobCriteria.state}}, {{jobCriteria.country}} </label>
                  </div>
                  <div v-for="other in jobOtherCriteria" :key="other.id">
                    <li type="disc" class="tw-py-5">{{other.content}}</li>
                  </div>
                </ul>
              </span>
            </div>
            <PostJobModal @close="closeShare" :job-info="jobDetails" :dialog="shareModal"/>
          </div>
        </div>
      </template>
      <template #bottomNav>
        <job-summary-footer :logo="company.company_logo"/>
      </template>
    </job-posting-layout>
  </div>
</template>

<script>
import JobPostingLayout from "../layouts/JobPostingLayout";
import JobSummaryFooter from "@/components/jobPosting/reuseables/JobSummaryFooter";
import BaseButton from "@/components/UIComponents/BaseButton";
import { BriefcaseIcon, MapPinIcon, } from "vue-feather-icons"
import {mapGetters} from 'vuex'
import {postJob, getCompanyProfile} from "@/services/api/APIService";
import SplashConfirmModal from "@/components/UIComponents/SplashConfirmModal";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import {getCriteria, getOtherCriteria} from "../../../services/api/APIService";
import PostJobModal from "@/components/UIComponents/PostJobModal";

export default {
  name: "JobPostingSummary",
  components: {
    PostJobModal,
    Loader,
    SplashConfirmModal, BaseButton, JobSummaryFooter, JobPostingLayout, BriefcaseIcon, MapPinIcon, },
  data() {
    return {
      pageLoading : false,
      loading: false,
      company: {},
      dialog: false,
      shareModal:false,
      jobDetails:{},
      title: 'Post this job',
      cancel: 'Cancel',
      post: 'Post Job',
      subTitle: 'Posting this job makes it live, you will not be able to make any changes to the job posting once you proceed.',
      jobCriteria:{},
      jobOtherCriteria : []
    }
  },
  computed: {
    ...mapGetters('jobPosting', ['jobInfo']),
    sessionStorageId() {
      let job = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"));
      return job.id;
    },
  },
  destroyed() {
    sessionStorage.removeItem('selectedRole')
  },
  methods: {
    closeShare(){
      this.shareModal = false
      // this.$router.push({path: '/dashboard/jobs'})
      sessionStorage.removeItem("returnedSelectedDetails")
    },
   async getShortlistingCriteria(){
      let data = {}
      data.id = this.sessionStorageId
      data.site_name = this.$siteName()
     await getCriteria(data).then(res => {
       this.jobCriteria= res.data
     }).catch(err =>{
            this.loading = false
            this.$displaySnackbar({message:err.response.data.detail, success:false})
          })
     await getOtherCriteria(data).then(res =>{
         this.jobOtherCriteria = res.data
       console.log(res.data,"Other criteria")
     })
         .catch(err =>{
           this.loading = false
           this.$displaySnackbar({message:err.response.data.detail, success:false})
         })
    },
    openModal() {
      this.dialog = true
    },
    closeModal() {
      this.dialog = false
      // this.$router.push({path:"/dashboard/jobs"})
      // sessionStorage.removeItem("returnedSelectedDetails")

    },
    convertNumber(x) {
      if (!x) return
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async createJobInfo(){
      this.pageLoading = true
      let data ={}
      data.id = this.sessionStorageId  || this.$route.query.job_id
      data.site_name = this.$siteName()
      await this.$store.dispatch('jobPosting/getAllJobInfo',data )
      this.pageLoading = false
    },
    handlePublishJob() {
      this.loading = true
      postJob(this.sessionStorageId).then( res=> {
        // if(res.statusText === 'OK') {
          const resMessage =  res.data.detail
          console.log(resMessage)
          this.jobDetails = {...this.company.company_logo,...this.jobInfo}
          this.loading = false
          this.$displaySnackbar({message:resMessage, success:true})
          this.shareModal = true
          this.dialog = false
        // }
      }).catch(err =>{
        this.loading = false
        this.$displaySnackbar({message:err.response.data.detail, success:false})
      })
    },
    async companyProfile() {
      let site_name = this.$siteName()
      await getCompanyProfile(site_name).then(res => {
        this.company = res.data

      })

    },
    processedDate(createdDate) {
      if (!createdDate) return;
      let day;
      let date, now
      day = createdDate.split("T")[0]
      let d1 = date = new Date(day);
      let d2 = now = new Date();
      if (d2.getTime() < d1.getTime()) {
        d1 = now;
        d2 = date;
      }
      let yd = d1.getYear();
      let yn = d2.getYear();
      let years = yn - yd;
      let md = d1.getMonth();
      let mn = d2.getMonth();
      let months = mn - md;
      if (months < 0) {
        years--;
        months = 12 - md + mn;
      }
      let dd = d1.getDate();
      let dn = d2.getDate();
      let days = dn - dd;
      if (days < 0) {
        months--;
        // figure out how many days there are in the last month
        d2.setMonth(mn, 0);
        days = d2.getDate() - dd + dn;
      }
      let weeks = Math.floor(days / 7);
      days = days % 7;
      if (years > 0) return years + 'year' +  (years > 1 ? "s" : "") + " "
      if (months > 0) return months + ' month' +  (months > 1 ? "s" : "") + " "
      if (weeks > 0) return weeks + ' week' +  (weeks > 1 ? "s" : "") + " "
      if(days > 0) return  days + ' day' +  (days > 1 ? "s" : "")  + " " ;
      return "Created Today";

    },
    getCurrency(currency) {

      if (!currency) return;
      const arr = currency.split(' ')
      const result = arr.slice(-1)

      return result[0].replace(/[()]/g, '')
    }

  },
  async created() {
    this.pageLoading = true
    await this.createJobInfo();
    await this.companyProfile();
    this.pageLoading = false
    await this.getShortlistingCriteria()

  }
}
</script>

<style scoped lang="scss">
.pre {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #3F3F3F;
}
.company-name {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  color: #475661;

}
.job-title {
  font-family: IBM Plex Serif;
  font-size: 24px;
  line-height: 31px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #001343;
}
.date {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  align-items: center;
  color: #9CABB5;

}
.details {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  color: #475661;
  list-style: disc !important;
}
.profile {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold ;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #001343;
}
.profile-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  align-items: center;
  color: #1E323F;
}
.loc {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #475661;
}
.edit-text{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
  cursor: pointer;

}
.edit-border-bottom{
  border-bottom: 1px solid #3F3F3F;
}
.custom-width{
  width: 100%;
  @media screen and (min-width: 1240px){
    width: 46%;
  }
}
</style>
