<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="550"
        transition="dialog-top-transition"
        persistent
    >
      <v-card class="tw-p-3 md:tw-p-5 cardBack">
<!--        <div class="tw-flex tw-justify-end">-->
<!--          <v-btn icon text @click="$emit('close', jobInfo.id)">-->
<!--            <v-icon color="black" class="ml-2">mdi-close</v-icon>-->
<!--          </v-btn>-->
<!--        </div>-->

        <div class="inner-content">
          <div class="mb-8">
            <checked-icon/>
          </div>

          <h3 class="tw-font-black mb-1 tw-text-xl main-title" style="font-size: 24px">{{ jobInfo.title }}</h3>
          <p class="inner-title">{{ text }}</p>
        </div>
        <v-divider/>
        <div class="tw-flex tw-justify-end align-center mb-2 mt-5">
          <base-button @click="viewJob()" class="mr-4" outlined depressed button-text="View Job"></base-button>
          <ShareJobsModal :dialog="shareDialog" @openShare="shareDialog = true" @close="closeModal" :job-title="jobInfo.title" :id="jobInfo.id" :logo="jobInfo.company_logo || noImage"
                          :job-type="jobInfo.type" :location="jobInfo.is_remote" :company="jobInfo.company_name"
                          style="color: #475661"/>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CheckedIcon from "@/components/onboarding/reuseables/checkedIcon";
import BaseButton from "@/components/UIComponents/BaseButton";
import ShareJobsModal from "@/components/jobPosting/reuseables/ShareJobsModal";

export default {
  name: "PostJobModal",
  components: {ShareJobsModal, BaseButton, CheckedIcon},
  props: {
    jobInfo: Object,
    dialog: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    console.log(this.jobInfo)
  },
  data: () => {
    return {
      shareDialog: false,
      text: "Job has been posted successfully!",
      noImage: 'https://images.unsplash.com/photo-1632169505206-9506f48761e6?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    }
  },

  methods: {
    async closeModal(){
      this.shareDialog = false
      // this.$emit('close')
    },
    openPost(){
      this.$emit('open')
    },
    viewJob(id) {
      this.closeModal()
      this.$router.push({path:`/dashboard/jobs/candidates/${this.$route.query.job_id}`})
      console.log(id)
      sessionStorage.removeItem("returnedSelectedDetails")
    }
  }
}
</script>

<style scoped lang="scss">
.cardBack {
  width: auto;
  min-height: 379px;
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}
.inner-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "DM Sans", serif;
  line-height: 31px;

}
.main-title{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #475661;
  @media (min-width: 650px) {
    white-space: pre;
  }
}
.inner-title{
  //font-family: IBM Plex Serif;
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #475661;
  @media (min-width: 650px) {
    white-space: pre;

  }

}
</style>
