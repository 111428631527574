<template>
  <JobPostingSummary/>
</template>

<script>
import JobPostingSummary from "@/components/jobPosting/components/JobPostingSummary";
export default {
  name: "JobSummary",
  components: {JobPostingSummary}
}
</script>

<style scoped>

</style>